import Navbar from "components/navbar";
import {getAssetFullUrl, getRoundName} from "helpers/utils";
import { useBracketLeagueDetail, useLeagueDetail } from "hooks/league";
import { useUserByID, useUserHistory } from "hooks/user";
import { BracketRoundDataInterface, defaultLeague } from "interfaces/league";
import { UserHistoryInterface } from "interfaces/user";
import { isEmpty } from "lodash";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
  TEModal,
  TEModalContent,
  TEModalDialog,
  TETabs,
  TETabsContent,
  TETabsPane,
} from "tw-elements-react";
import { PlayerStatus, TabItem } from ".";
import Icon from "../../../components/icon";

enum MatchResult {
  Win = "Thắng",
  Loss = "Thua",
}

const CustomTabPane = ({
  children,
  isActive,
  disabled,
  onClick,
}: {
  children: ReactNode;
  isActive: boolean;
  disabled?: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      onClick={() => (disabled ? null : onClick())}
      className={`font-exo text-[14px] rounded-md px-5 py-[1px] shrink-0 ${
        isActive ? "bg-[#1CB4A3]" : ""
      } ${
        !disabled
          ? "cursor-not-allowed text-white"
          : "cursor-pointer text-[#848484]"
      }
      
      `}
    >
      {children}
    </div>
  );
};

const PlayerCard = ({
  playerId,
  status,
  playerScore,
  position,
  setShow,
  setActivePlayer,
}: {
  playerId: string;
  status?: PlayerStatus;
  playerScore?: number;
  position?: number;
  setShow: Dispatch<SetStateAction<boolean>>;
  setActivePlayer: Dispatch<SetStateAction<string>>;
}) => {
  const userInfo = useUserByID(playerId);
  const textColor = status === PlayerStatus.Win ? "#0BD8B6" : "#777B84";

  return (
    <div
      onClick={() => {
        setShow(true);
        setActivePlayer(playerId);
      }}
      style={{
        color: textColor,
      }}
      className={`grid grid-cols-9 items-center font-light justify-between text-[14px] py-1 px-2 rounded-md`}
    >
      <div className="col-span-1 text-center "> {position}</div>
      <div className="col-span-5 flex items-center justify-start gap-5">
        <div className="w-[28px] h-[28px] rounded-full overflow-hidden bg-white shrink-0">
          <img src={getAssetFullUrl(userInfo?.profilePicture)} alt="" />
        </div>
        <p>{userInfo?.name || "--"}</p>
      </div>
      <div className="col-span-2 flex justify-start gap-5">
        {userInfo?.ranking?.class || "--"}{" "}
        {userInfo?.score ? `(${userInfo?.score || "--"})` : ""}
      </div>
      <div className="col-span-1 text-center "> {playerScore || "--"}</div>
    </div>
  );
};

const PlayerPair = ({
  match,
  setShow,
  setPlayerId,
}: {
  match: BracketRoundDataInterface;
  setShow: Dispatch<SetStateAction<boolean>>;
  setPlayerId: Dispatch<SetStateAction<string>>;
}) => {
  if (isEmpty(match)) return null;

  return (
    <div className="border-b-[0.5px] py-2 w-full border-[#777B84]">
      <PlayerCard
        setShow={setShow}
        setActivePlayer={setPlayerId}
        playerId={match?.playerId}
        playerScore={match?.playerScore}
        position={match?.position*2-1}
        status={
          match?.winnerId === match?.playerId && match?.playerId
            ? PlayerStatus.Win
            : PlayerStatus.Loss
        }
      />
      <PlayerCard
        setActivePlayer={setPlayerId}
        setShow={setShow}
        playerId={match?.secondPlayerId}
        playerScore={match?.secondPlayerScore}
        position={match?.position*2}
        status={
          match?.winnerId === match?.secondPlayerId && match?.secondPlayerId
            ? PlayerStatus.Win
            : PlayerStatus.Loss
        }
      />
    </div>
  );
};

const MatchRecord = ({
  match,
  roundName,
  playerId,
}: {
  match: UserHistoryInterface;
  roundName: string;
  playerId: string;
}) => {
  const firstIsCurrent = playerId === match?.data?.playerId;

  const opponent = useUserByID(
    firstIsCurrent ? match?.data?.secondPlayerId : match?.data?.playerId
  );

  if (match?.data?.hasResult === false) return null;

  const rate = firstIsCurrent
    ? `${match?.data?.playerScore || 0} - ${
        match?.data?.secondPlayerScore || 0
      }`
    : ` ${match?.data?.secondPlayerScore || 0} - ${
        match?.data?.playerScore || 0
      }`;

  const result = firstIsCurrent
    ? match?.data?.winnerId === match?.data?.playerId
      ? MatchResult.Win
      : MatchResult.Loss
    : match?.data?.winnerId === match?.data?.playerId
    ? MatchResult.Loss
    : MatchResult.Win;

  const name = getRoundName(roundName);

  return (
    <div className="grid grid-cols-8 text-center items-center">
      <div className="col-span-2 items-start">{name}</div>
      <div className="col-span-3">
        <div className="flex items-center gap-2">
          <div className="w-8 h-8 rounded-full overflow-hidden bg-white">
            <img src={getAssetFullUrl(opponent?.profilePicture)} alt="" />
          </div>
          <p className="text-[12px]">{opponent?.name}</p>
        </div>
      </div>
      <div
        style={{
          color: result === MatchResult.Win ? "#0BD8B6" : "#777B84",
        }}
        className="col-span-2"
      >
        {result}
      </div>
      <div className="col-span-1">{rate}</div>
    </div>
  );
};

const PlayerHistory = ({
  show,
  setShow,
  playerId,
  roundName,
}: {
  show: boolean;
  playerId: string;
  roundName: string;
  setShow: Dispatch<SetStateAction<boolean>>;
}) => {
  const { id = "" } = useParams();
  const user = useUserByID(playerId);
  const history = useUserHistory(id, playerId);

  return (
    <TEModal className="zIndexTop" show={show} setShow={setShow}>
      <TEModalDialog centered={true}>
        <TEModalContent className="mx-4 overflow-hidden bg-[#343458]">
          <div className="bg-[#343458]">
            <section className="flex items-start justify-between mx-6 mt-6 mb-2">
              <div className="flex gap-4 items-center">
                <div className="rounded-full w-[45px] h-[45px] overflow-hidden shrink-0 bg-white">
                  <img src={getAssetFullUrl(user?.profilePicture)} alt="" />
                </div>
                <div className="flex flex-col items-start justify-between">
                  <p className="text-[14px]">{user?.name} </p>
                  <p className="text-[12px]">
                    {user?.ranking?.class}({user?.score})
                  </p>
                </div>
              </div>

              <div onClick={() => setShow(false)}>x</div>
            </section>

            <section className="bg-[#1E1E32] pb-3 text-[#B0B4BA] rounded-md m-2">
              <div className="league-content-tab">
                <TETabs className="w-full text-center">
                  <TabItem active onClick={() => {}}>
                    Lịch sử đấu
                  </TabItem>
                </TETabs>
              </div>

              <section className=" text-[12px]">
                <div className="grid grid-cols-8 font-semibold text-center">
                  <div className="col-span-2">VÒNG</div>
                  <div className="col-span-3 items-start text-start">
                    ĐỐI THỦ
                  </div>
                  <div className="col-span-2">KẾT QUẢ</div>
                  <div className="col-span-1">TỈ SỐ</div>
                </div>

                <div className="flex flex-col gap-5 my-4">
                  {history.length && history.map((match) => (
                      match.data && <MatchRecord
                        match={match}
                        roundName={match.roundName}
                        playerId={playerId}
                      />
                    ))}
                </div>
              </section>
            </section>
          </div>
        </TEModalContent>
      </TEModalDialog>
    </TEModal>
  );
};

const PlayoffAllSchedule = () => {
  const { id = "" } = useParams();
  const [activePlayer, setActivePlayer] = useState("");
  const league = useLeagueDetail(id, defaultLeague);
  const [showDialog, setShowDialog] = useState(false);
  const bracketInfo = useBracketLeagueDetail(id);
  const [activeTab, setActiveTab] = useState(
    bracketInfo?.roundData?.[0]?.roundName
  );
  const navigate = useNavigate();

  useEffect(() => {
    setActiveTab(bracketInfo?.roundData?.[0]?.roundName);
  }, [bracketInfo?.roundData]);


  return (
    <div className="py-4 mx-2">
      <Navbar title="LỊCH THI ĐẤU ĐẦY ĐỦ" />

      <section>
        <h2 className="font-semibold text-[30px]">{league?.name} <a
            className="inline-block text-2xl text-accent -rotate-45"
            onClick={() => navigate("/league/" + league.id)}
        >
          <Icon icon={"arrow-right"}/>
        </a></h2>
        <p className="text-[#B0B4BA] text-[14px]">
          {league.startDateObj.format("DD/MM/YY")} -{" "}
          {league.endDateObj.format("DD/MM/YY")}
        </p>
      </section>

      <section className="my-5 gap-y-4 w-full">
        <div className="flex text-center items-center justify-center font-semibold text-[16px]">
          Lịch thi đấu
        </div>

        <div className="league-content-tab w-full mt-4">
          <div className="overflow-x-scroll w-full border border-white rounded-md p-1">
            <TETabs
              style={{
                flexWrap: "nowrap",
                marginBottom: 0,
              }}
            >
              {bracketInfo?.roundData?.map((round, index) => {
                const name = getRoundName(round?.roundName);

                return (
                  <CustomTabPane
                    key={index}
                    isActive={activeTab === round?.roundName}
                    disabled={isEmpty(round?.data?.[0])}
                    onClick={() => setActiveTab(round?.roundName)}
                  >
                    {name}
                  </CustomTabPane>
                );
              })}
            </TETabs>
          </div>
        </div>

        <TETabsContent>
          {bracketInfo?.roundData?.map((round, index) => {
            return (
              <TETabsPane key={index} show={activeTab === round?.roundName}>
                {round?.data?.map((match, _) => {
                  return (
                    <PlayerPair
                      setPlayerId={setActivePlayer}
                      match={match}
                      setShow={setShowDialog}
                    />
                  );
                })}
              </TETabsPane>
            );
          })}
        </TETabsContent>
      </section>

      <PlayerHistory
        show={showDialog}
        setShow={setShowDialog}
        playerId={activePlayer}
        roundName={activeTab as string}
      />
    </div>
  );
};

export default PlayoffAllSchedule;
