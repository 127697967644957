import { findPrize } from "helpers/findPrize";
import { usePlayerList } from "hooks/user";
import { UserInterface } from "interfaces/user";
import { TETabs, TETabsItem } from "tw-elements-react";
import {getAssetFullUrl} from "../../../../helpers/utils";

const UserRow = ({ user }: { user: UserInterface }) => {
  const prize = findPrize(user?.leaguePosition);

  return (
    <div className="grid grid-cols-10 py-2 border-t-[0.5px] border-[#5A6169] text-[#B0B4BA] text-center">
      <div className="col-span-1">
        {[1, 2, 3].indexOf(user?.leaguePosition) !== -1 ? (
          <img
            src={`/rank_${user?.leaguePosition}.svg`}
            alt={user?.leaguePosition?.toString()}
          />
        ) : (
          user?.leaguePosition
        )}
      </div>
      <div className="col-span-4 text-start flex gap-2">
        <div className="w-[28px] h-[28px] rounded-full overflow-hidden">
          <img
            src={getAssetFullUrl(user?.player?.profilePicture)}
            alt="user_pic"
            width={28}
            height={28}
          />
        </div>
        <div className="text-[14px]">{user?.player?.name}</div>
      </div>
      <div className="col-span-1 text-start">{user?.ranking}</div>
      <div className="col-span-4 flex flex-col items-end mr-4">
        <div className="font-medium">{prize?.cash}</div>
        <div className="font-light">{prize?.gift}</div>
      </div>
    </div>
  );
};

const CompletedLeaguePlayers = ({ leagueID }: { leagueID: string }) => {
  const players = usePlayerList(leagueID);

  return (
    <section className=" mt-2 bg-[#1E1E32]" id="user-list">
      <TETabs className="w-full bg-[#1E1E32]">
        <TETabsItem
          wrapperClass="flex-1 bg-[#1E1E32]"
          className="w-full customTab"
          active
          tag="button"
          color="primary"
        >
          Kết quả chung cuộc
        </TETabsItem>
      </TETabs>
      <div className="grid grid-cols-10 py-2 items-center text-center text-[12px] text-[#D9D9D9] font-oswald">
        <div className="col-span-1">#</div>
        <div className="col-span-4">CƠ THỦ</div>
        <div className="col-span-1 text-start">ĐIỂM</div>
        <div className="col-span-4">GIẢI THƯỞNG</div>
      </div>
      {players?.map((item) => (
        <UserRow user={item} />
      ))}
    </section>
  );
};

export default CompletedLeaguePlayers;
