import Icon from "components/icon";
import Navbar from "components/navbar";
import {leaguePrize} from "helpers/findPrize";
import * as SBService from "helpers/sportbookService";
import {getAssetFullUrl, toCurrency} from "helpers/utils";
import {useLeagueDetail} from "hooks/league";
import {defaultLeague} from "interfaces/league";
import moment from "moment";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useParams} from "react-router-dom";
import {useRecoilState} from "recoil";
import {userState} from "states/common";
import {
    TEModal,
    TEModalBody,
    TEModalContent,
    TEModalDialog,
    TETabs,
    TETabsContent,
    TETabsItem,
    TETabsPane,
} from "tw-elements-react";
import CompletedLeaguePlayers from "./tables/Completed";
import OngoingLeaguePlayers from "./tables/Ongoing";
import {UpcomingLeagueUserList} from "./tables/Upcoming";

const SectionHeader = ({children}: { children: string }) => {
    return (
        <h3 className="text-[16px] leading-[22px] text-[#EDEEF0] mt-3">
            {children}
        </h3>
    );
};

const JoinBtn = ({notAbleToJoin}: { notAbleToJoin: boolean }) => {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const {id = ""} = useParams();
    const [user] = useRecoilState(userState);

    if (notAbleToJoin) return null;

    const registered = user?.leagues?.find((league) => league.id === id);

    const handleJoin = async () => {
        try {
            setLoading(true);
            await SBService.joinLeagueById(id);
            window.location.reload();
        } catch (error: any) {
            console.error("join league error: ", error);
            const rspBody = error.response.data;
            if (rspBody && rspBody.error) {
                if (rspBody.error === 'playerNotCalibrated') {
                    return toast.error("Tài khoản chưa hoàn tất khảo hạch không thể tham gia giải đấu.");
                } else if (rspBody.error === 'playerRankIsNotAllowed') {
                    return toast.error("Không thể tham gia giải đấu do xếp hạng không phù hợp.");
                } else {
                    return toast.error("Không thể tham gia giải đấu");
                }
            } else {
                return toast.error("Không thể tham gia giải đấu");
            }
        } finally {
            setShowModal(false);
            setLoading(false);
        }
    };

    return (
        <>
            <button
                onClick={() => setShowModal(true)}
                disabled={!!registered || notAbleToJoin}
                className="font-exo disabled:bg-[#B0B4BA] rounded-[4px] bg-[#26AA99] text-white px-[13px] py-[6px] text-[10px]"
            >
                {registered ? "ĐÃ THAM GIA" : "THAM GIA NGAY"}
            </button>
            <TEModal className="zIndexTop" show={showModal} setShow={setShowModal}>
                <TEModalDialog className="h-full" centered={true}>
                    <TEModalContent>
                        <TEModalBody className="bg-[#1E1E32] text-center border border-[#084843] rounded">
                            <div
                                className="w-full text-left border border-dotted rounded-[0.5rem] py-2 px-4 border-[#4D3000] bg-[#16120C]">
                                <p className="font-exo text-sm font-medium uppercase text-[#FFE7B3]">
                                    Xác nhận tham gia giải đấu
                                </p>
                                <div className="font-exo text-[#FFCA16] text-[14px]">
                                    Ban đang tham gia vào giải đấu. Vui lòng xác nhận!
                                </div>
                            </div>
                            <div className="flex">
                                <button
                                    disabled={loading}
                                    onClick={handleJoin}
                                    className="block w-1/2 h-12 bg-[#0EB39E] text-center uppercase text-lg rounded border-2 border-[#ADF0DD] button-custom-shadow mt-6 mr-2 text-white"
                                >
                                    {" "}
                                    {"Xác nhận"}
                                </button>
                                <button
                                    onClick={handleJoin}
                                    className="block w-1/2 h-12 text-center uppercase text-lg rounded text-gray-11 border-[#B0B4BA] border-2 mt-6 mr-2"
                                >
                                    {" "}
                                    {"Để sau"}
                                </button>
                            </div>
                        </TEModalBody>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
        </>
    );
};

const LeagueDetail = () => {
    const {id = ""} = useParams();
    const [tab, setTab] = useState("1");
    const [notAbleToJoin, setNotAbleToJoin] = useState(true);

    const league = useLeagueDetail(id, defaultLeague);
    const banner = getAssetFullUrl(league.banner);
    const [user, _] = useRecoilState(userState);

    const leagueStarted = moment().isAfter(league.startDateObj);
    const leagueEnd = moment().isAfter(league.endDateObj);
    const leagueCompleted = leagueStarted && moment().isAfter(league.endDateObj);
    const isPairing = league.type === 'ELIMINATION' && moment().isAfter(league.startPairingDateObj) && moment().isBefore(league.endPairingDateObj.endOf('day'));

    useEffect(() => {
        if (user.id && league.id) {
            const now = moment();
            if (now.isSameOrAfter(league.startRegistrationDateObj) && now.isSameOrBefore(league.endRegistrationDateObj.endOf('day'))) {
                if (league.ranks && league.ranks.length > 0) {
                    if (user.ranking) {
                        const allowed = league.ranks.find(x => x.code === user.ranking.code);
                        if (allowed) {
                            setNotAbleToJoin(false)
                        }
                    }
                } else {
                    setNotAbleToJoin(false)
                }
            }
        }
    }, [user, league]);

    return (
        <div>
            <Navbar title="THÔNG TIN GIẢI ĐẤU"/>

            {banner ? (
                <img alt="banner" className="rounded w-full" src={banner}/>
            ) : null}

            <h1 className="text-[30px] mt-3 text-center">{league.name}</h1>

            <div className="league-content-tab rounded w-full">
                <TETabs className="w-full bg-[#1E1E32]">
                    <TETabsItem
                        wrapperClass="flex-1 bg-[#1E1E32]"
                        className="w-full py-3 customTab"
                        active={tab === "1"}
                        onClick={() => setTab("1")}
                        tag="button"
                        color="primary"
                    >
                        Thông tin chung
                    </TETabsItem>
                    <TETabsItem
                        wrapperClass="flex-1 bg-[#1E1E32]"
                        className="w-full py-3"
                        active={tab === "2"}
                        onClick={() => setTab("2")}
                        tag="button"
                        color="primary"
                    >
                        Thi đấu
                    </TETabsItem>
                </TETabs>

                <TETabsContent>
                    <TETabsPane show={tab === "1"} className="text-[12px]">
                        <section className="py-3 px-2 mt-2 bg-[#1E1E32]" id="time">
                            <div className="flex items-center justify-between content-start text-[#B0B4BA]">
                                <div className="flex items-start gap-5">
                                    <Icon size={22} icon={"fa-calendar"}/>
                                    <div className="font-exo leading-[20px]">
                                        {!isPairing ? league.startRegistrationDateObj && (
                                            <div className="flex gap-2">
                                                <p className="font-thin">Đăng ký:</p>
                                                <span className="text-[#0EB39E]">
                          {league.startRegistrationDateObj.format("DD/MM/YY")} -{" "}
                                                    {league.endRegistrationDateObj.format("DD/MM/YY")}
                        </span>
                                            </div>
                                        ) : <div className="flex gap-2">
                                            <p className="font-thin">Bốc thăm:</p>
                                            <span className="text-[#0EB39E]">
                          {league.startPairingDateObj.format("DD/MM/YY")} -{" "}
                                                {league.endPairingDateObj.format("DD/MM/YY")}
                        </span>
                                        </div>}
                                        {league.startDateObj && (
                                            <div className="flex gap-2">
                                                <p className="font-thin">Thi đấu: </p>
                                                <span>
                          {league.startDateObj.format("DD/MM/YY")} -{" "}
                                                    {league.endDateObj.format("DD/MM/YY")}
                        </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <JoinBtn notAbleToJoin={notAbleToJoin}/>
                            </div>
                            <div className="flex items-start justify-start text-[#B0B4BA] mt-4">
                                <div className="mr-5">
                                    <Icon size={22} icon={"map-marker-alt"}/>
                                </div>
                                <div className="font-exo">
                                    <p className="underline">
                                        {league?.venues?.map((venue) => venue.name).join(", ")}
                                    </p>
                                </div>
                            </div>
                        </section>

                        <SectionHeader>Giải thưởng</SectionHeader>
                        <section className="py-3 px-2 mt-2 bg-[#1E1E32]">
                            {league.leaguePrizes && league.leaguePrizes?.length > 0 ?
                                <div className="grid grid-cols-12 gap-4 text-[#B0B4BA]">
                                    <div className="col-span-3">
                                        <h4 className="text-[14px] font-semibold">Thứ hạng</h4>
                                    </div>
                                    <div className="col-span-5">
                                        <h4 className="text-end text-[14px] font-semibold">
                                            Giải thưởng tiền mặt
                                        </h4>
                                    </div>
                                    <div className="col-span-4">
                                        <h4 className="text-[14px] font-semibold">Hiện vật</h4>
                                    </div>
                                </div>
                                : <></>}

                            {league.leaguePrizes && league.leaguePrizes?.length > 0 ? league.leaguePrizes.map((item, id) => {
                                return (
                                    <div key={`prize-${id}`} className="grid grid-cols-12 gap-4 text-[#B0B4BA]">
                                        <div className="col-span-3">
                                            <h4 className="text-[14px] text-start">{item.label}</h4>
                                        </div>
                                        <div className="col-span-5">
                                            <h4 className="text-end text-[14px]">{item.prizeInCash ? (item.prizeInCash + ' VND') : '0'}</h4>
                                        </div>
                                        <div className="col-span-4">
                                            <h4 className="text-[14px]">{item.prizeInKind}</h4>
                                        </div>
                                    </div>
                                );
                            }) : <div dangerouslySetInnerHTML={{__html: league.prize}}></div>}
                        </section>

                        <SectionHeader>Thể lệ thi đấu</SectionHeader>
                        <section className="py-[22px] px-4 bg-[#1E1E32] mt-2 raw-css">
                            {league.fee > 0 && (
                                <div className="font-exo leading-normal font-bold text-[14px] mb-2">Lệ phí tham
                                    gia: {toCurrency(league.fee.toString())} vnđ</div>)}
                            {league.rule ? <div dangerouslySetInnerHTML={{__html: league.rule}}></div> : <>
                                <h4 className="text-[#EDEEF0] font-semibold text-[14px]">
                                    Luật cơ bản
                                </h4>
                                <p className="text-[#B0B4BA] text-[14px] font-thin mt-[5px]">
                                    - Người chơi cố gắng ăn càng nhiều bi càng tốt trong vòng 2 mạng{" "}
                                    <br/>
                                    - Sau cơ phá, 10 bi đầu ăn tự do, 5 bi cuối ăn theo thứ tự từ bé
                                    tới lớn <br/>
                                    - Hết 1 ván, xếp bi lại, phá tiếp từ đầu, tính cộng dồn số bi
                                    cho tới khi hết 2 mạng <br/>- Tại các mốc thưởng, người chơi có
                                    quyền dừng lại hoặc chơi tiếp. Nếu đi tiếp, phần thưởng ở mốc
                                    trước đó không được tính
                                </p>
                                <h4 className="text-[#EDEEF0] font-semibold text-[14px] mt-5">
                                    Luật bổ sung
                                </h4>
                                <p className="text-[rgb(176,180,186)] text-[14px] font-thin mt-[5px]">
                                    - Số lần thử: <br/>
                                    + Mỗi người chơi có 3 lần thử với 2 mạng mỗi lần để đạt được mức
                                    nhận thưởng cơ bản đầu tiên là 15 bi (hết 1 ván) <br/>
                                    + Nếu người chơi đạt được mốc 15 bi (hết 1 ván), người chơi
                                    không còn lượt thử nữa <br/>+ Nếu trơng 3 lần thử đầu tiên,
                                    người chơi không lần nào ăn được nhiều hơn 10 bi, người chơi
                                    được thử thêm lần thứ 4 với 3 mạng <br/>
                                    + Nếu trong lần thử thứ 4, người chơi vẫn không ăn được nhiều
                                    hơn 10 bi, người chơi được thử lần thứ 5 với 4 mạng <br/>+ Nếu
                                    trong lần thử thứ 5, người chơi vẫn không ăn được nhiều hơn 10
                                    bi, người chơi được thử lần thứ 6 với 5 mạng- Mạng: <br/>
                                    + Người chơi mất 1 mạng trong những tình huống sau: <br/>
                                    ~ Phá chết cái / bay bi ra ngoài
                                    <br/>
                                    ~ Đánh lỗi (chết cái, làm bi di chuyển không phải do đầu cơ,
                                    đánh bi bay ra ngoài...) <br/>
                                    ~ Đánh trượt <br/>
                                    ~ Đánh rùa, đánh sai bi, hoặc sai lỗ đã chỉ (Trong tình huống
                                    cân bi, a băng, đôn bi phải chỉ bi, chỉ lỗ) <br/>
                                    + Mỗi khi mất mạng, người chơi được đặt bi tự do <br/>
                                    - Phần thưởng: <br/>
                                    + Người chơi khi dừng cuộc chơi, cần yêu cầu trọng tài xác nhận.
                                    Khi được xác nhận, kết quả hiển thị trong app của người chơi
                                    (Phần "Hồ sơ"), người chơi cầm app ra để xác nhận với quầy và
                                    nhận thưởng tương ứng. <br/>+ Trừ mốc chơi đầu tiên, người chơi
                                    được nhận đi nhận lại, từ mốc thứ 2 trở đi, chỉ được nhận 1 lần
                                    (Ví dụ: người chơi đã nhận được mốc thưởng số 3 - 300K, người
                                    chơi chỉ có thể dừng lại ở mốc 1 hoặc mốc 4)
                                </p>
                            </>}
                            {
                                league.minMatchesPerWeek > 0 && league.minWeekReachMinMatchesRequired > 0 && (
                                    <div className="font-exo">
                                        <h4 className="text-[#EDEEF0] text-[14px] mt-2">
                                            Điều kiện hoàn cọc
                                        </h4>
                                        <ul>
                                            <li><span
                                                className="text-[12px] text-[#B0B4BA]">Hoàn thành tối thiểu {league.minMatchesPerWeek} trận mỗi tuần trong {league.minWeekReachMinMatchesRequired} tuần để được hoàn cọc.</span>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            }
                        </section>
                    </TETabsPane>

                    <TETabsPane show={tab === "2"} className="text-[12px]">
                        <section className="py-3 px-2 mt-2 bg-[#1E1E32]" id="time">
                            <div className="flex items-center justify-between content-start text-[#B0B4BA]">
                                <div className="flex items-start gap-5">
                                    <Icon size={22} icon={"fa-calendar"}/>
                                    <div className="font-exo leading-[20px]">
                                        {!isPairing ? league.startRegistrationDateObj && (
                                            <div className="flex">
                                                <p>Đăng ký:</p>
                                                <span className="ml-2 text-[#0EB39E]">
                          {league.startRegistrationDateObj.format("DD/MM/YY")} -{" "}
                                                    {league.endRegistrationDateObj.format("DD/MM/YY")}
                        </span>
                                            </div>
                                        ) : league.startPairingDateObj && (<div className="flex">
                                            <p>Bốc thăm:</p>
                                            <span className="ml-2 text-[#0EB39E]">
                          {league.startPairingDateObj.format("DD/MM/YY")} -{" "}
                                                {league.endPairingDateObj.format("DD/MM/YY")}
                        </span>
                                        </div>)}
                                    </div>
                                </div>
                                <JoinBtn notAbleToJoin={notAbleToJoin}/>
                            </div>
                        </section>
                        {(!leagueStarted || league.type === 'ELIMINATION') && (
                            <UpcomingLeagueUserList key={'up-comming'} leagueID={id as string}/>
                        )}
                        {leagueStarted && league.type !== 'ELIMINATION' && !leagueCompleted && (
                            <OngoingLeaguePlayers leagueID={id as string}/>
                        )}
                        {leagueCompleted && league.type !== 'ELIMINATION' && (
                            <CompletedLeaguePlayers leagueID={id as string}/>
                        )}
                    </TETabsPane>
                </TETabsContent>
            </div>
        </div>
    );
};

export default LeagueDetail;
