export interface PrizeInterface {
  id: string;
  cash: string;
  gift: string;
}

export const leaguePrize: PrizeInterface[] = [
  {
    id: "1",
    cash: "40.000.000",
    gift: "Cúp + Cơ",
  },
  {
    id: "2",
    cash: "18.000.000",
    gift: "Bằng + Cơ",
  },
  {
    id: "3",
    cash: "8.000.000",
    gift: "Bằng + Lơ",
  },
  {
    id: "4",
    cash: "5.000.000",
    gift: "Bằng + Lơ",
  },
  {
    id: "5",
    cash: "4.000.000",
    gift: "Bằng + Lơ",
  },
  {
    id: "6",
    cash: "3.000.000",
    gift: "Lơ",
  },
  {
    id: "7",
    cash: "2.000.000",
    gift: "Lơ",
  },
  {
    id: "8",
    cash: "1.000.000",
    gift: "Lơ",
  },
  {
    id: "9",
    cash: "0",
    gift: "Lơ",
  },
  {
    id: "10",
    cash: "0",
    gift: "Lơ",
  },
  {
    id: "11-20",
    cash: "0",
    gift: "Áo đấu",
  },
];

export const findPrize = (position: number) => {
  for (let i = 0; i < leaguePrize.length; i++) {
    if (leaguePrize[i].id === position.toString()) {
      return leaguePrize[i];
    }
  }

  return leaguePrize[leaguePrize.length - 1];
};
